export const HOMEPAGE = "/tasks";
export const LOGGER = "SessionStack"; // or "SessionStack"
const Matt_IP = "10.1.10.171";
// const Matt_client_id = "08ae4631-75d8-4aa7-b153-7284588381fa";
const Kurt_IP = "10.1.10.192";
// const Kurt_client_id = "696dfbd7-2752-4ad5-9132-912f1d044682";
const Justin_IP = "10.1.10.195";
// const Justin_client_id = "0fb79d14-3465-4726-8337-610ab58c5e46";

//IP for QB
// const QB_IP ="52.149.6.218:8080"
const PRD_QB_IP ="acctsync.projul.com";
const QB_IP ="tst-acctsync.projul.com";
const usePRDInLocalhost = true;
const useTSTInLocalhost = false;
const useLocalFunctions = false;

//WEB APP INSIGHTS STUFF IS IN index.html
export const appConfig = (() => {
  console.log(window.location.hostname);
  if (
    window.location.hostname === "app.projul.com" ||
    window.location.hostname === "prj-prd-wus2-tmgr-xxxreact-01.trafficmanager.net" ||
    window.location.hostname === "prj-prd-wus2-xxwa-xxxreact-01.azurewebsites.net"
  ) {
    window.env = "PRD";
    return {
      stripeAccountId: 'price_1M6jVMLNiglk2O80QT6HeHCJ',
      subscriptionApi: 'https://api.projul.com/signup/v1/billing/subscription',
      billingApi: 'https://prj-prd-wus2-xfna-document-01.azurewebsites.net/api/',
      stripeKey: "pk_live_9z3kNbZRtrIzHZvVsjljK5ih00In2nxJCo",
      auth0_domain: "signin.projul.com",
      auth0_clientId: "9iiqN6ZSsymYtzt7CNBUtZIxH0rILn06",
      auth0_audience: "ProjulPrdAPI",
      auth0_db: "ProjulPrdDatabase",
      qbdClientDownload: "https://prjprdxcusxxsaxxaadb2c01.blob.core.windows.net/$root/ProjulSyncManagerForQBD.exe",
      logRocketKey: 'umgzza/projul-prod',
      // apiUrl: "https://api.projul.com/documents/v1/",
      // accounting_uri: "https://api.projul.com/accountingservices/v1/",
      // filesApi: "https://api.projul.com/files/v1/",

      v2Url: 'https://appv2.projul.com/',
      apiUrl: "https://prj-prd-wus2-xfna-document-01.azurewebsites.net/api/",
      reconcileApi: "https://prj-prd-wus2-xfna-intgrtns-01.azurewebsites.net/api/",
      accounting_uri: "https://prj-prd-wus2-xfna-acctsvcs-01.azurewebsites.net/api/",
      filesApi: "https://prj-prd-wus2-xfna-xxxfiles-01.azurewebsites.net/api/",
      geolocApi: "https://prj-prd-wus2-xfna-xxgeoloc-01.azurewebsites.net/api/",
     // qbBaseUrl: `http://${QB_IP}/`,
      qbBaseUrl: `https://${PRD_QB_IP}/`,

      syncApi: "https://prj-prd-wus2-xfna-xxxxsync-01.azurewebsites.net/api/",
      syncManagerLink: 'https://acctsync.projul.com/Files/Projul%20Sync%20Manager.zip',
      remoteConnectorLink: 'https://acctsync.projul.com/Files/Remote%20Connector.zip',
      calendarApi: "https://prj-prd-wus2-xfna-intgrtns-01.azurewebsites.net/api/GoogleCalendar/CheckUserAvailableSlot",
      emailApi: "https://prj-prd-wus2-xfna-xxxemail-01.azurewebsites.net/api/",
      redirectUri: "https://app.projul.com/",
      demoLinkAdder: "",
      portal_url: "https://portal.projul.com/",
      mapbox_key: "pk.eyJ1IjoicHJvanVsIiwiYSI6ImNrYXljNDFpbDAxMnEycXF0ZjB5azhmeXMifQ.JrexLCHFHT-FfzO4Pvqaqg",
      environment: "PRD",
      dromoKey: 'f6d767f4-170c-4541-b9bf-01bf13c28224',
      wePayConfig: {
        apiUrl: "https://prj-prd-wus2-xfna-xxxwepay-01.azurewebsites.net/api/accounts/",
        loginUrl: 'https://wepay.com/login',
        kycUrl: 'https://home.wepay.com/verify/',
      }
    }
  }
  else if (window.location.hostname === "dmo-app.projul.com" || window.location.hostname === "prj-dmo-wus2-xxwa-xxxreact-01.azurewebsites.net") {
    window.env = "DMO";
    return {
      subscriptionApi: 'https://prj-dmo-wus2-xfna-xxsignup-01.azurewebsites.net/api/billing/subscription',
      billingApi: 'https://prj-dmo-wus2-xfna-document-01.azurewebsites.net/api/',
      stripeKey: "pk_test_XvxbqWCFOxySfV6j1Ekv4xVo002897fs6F",
      auth0_domain: "dmoprojul.us.auth0.com",
      auth0_clientId: "fn3XW6urIohS0nRM0b2eKaSQLBnTLa85",
      auth0_audience: "ProjulDmoAPI",
      auth0_db: "ProjulDmoDatabase",
      qbdClientDownload: "https://prjdmoxcusxxsaxxaadb2c01.blob.core.windows.net/$root/ProjulSyncManagerForQBD.exe",
      logRocketKey: 'umgzza/projul-test',
      v2Url: 'https://projulv2-portal-app.azurewebsites.net/',
      apiUrl: "https://prj-dmo-wus2-xfna-document-01.azurewebsites.net/api/",
      reconcileApi: "https://prj-dmo-wus2-xfna-intgrtns-01.azurewebsites.net/api/",
      accounting_uri: "https://prj-dmo-wus2-xfna-acctsvcs-01.azurewebsites.net/api/",
      filesApi: "https://prj-dmo-wus2-xfna-xxxfiles-01.azurewebsites.net/api/",
      geolocApi: "https://prj-dmo-wus2-xfna-xxgeoloc-01.azurewebsites.net/api/",
      // qbBaseUrl: `http://${QB_IP}/`,
      qbBaseUrl: `https://${QB_IP}/`,

      syncApi: "https://prj-dmo-wus2-xfna-xxxxsync-01.azurewebsites.net/api/",
      syncManagerLink: 'https://tst-acctsync.projul.com/Files/Projul%20Sync%20Manager.zip',
      remoteConnectorLink: 'https://tst-acctsync.projul.com/Files/Remote%20Connector.zip',
      calendarApi: "https://prj-dmo-wus2-xfna-intgrtns-01.azurewebsites.net/api/GoogleCalendar/CheckUserAvailableSlot",
      emailApi: "https://prj-dmo-wus2-xfna-xxxemail-01.azurewebsites.net/api/",
      redirectUri: "https://dmo-app.projul.com/",
      demoLinkAdder: "&test=true",
      portal_url: "https://dmo-portal.projul.com/",
      mapbox_key: "pk.eyJ1IjoicHJvanVsIiwiYSI6ImNrYXljM2ViNzBoY3MydW9hd3hlY2lsdTEifQ.SiXym3YWxVUklDZSs6Q2kA",
      environment: "DMO",
      wePayConfig: {
        apiUrl: "https://prj-dmo-wus2-xfna-xxxwepay-01.azurewebsites.net/api/accounts/",
        loginUrl: 'https://stage.wepay.com/login',
        kycUrl: 'https://stage-home.wepay.com/verify/',
      }
    }
  }
  else if (window.location.hostname === "tst-app.projul.com" || window.location.hostname === "prj-tst-wus2-xxwa-xxxreact-01.azurewebsites.net") {
    window.env = "TST";
    return {
      stripeAccountId: 'price_1M2bt0LNiglk2O80Pc0Jlo9Z',
      subscriptionApi: 'https://prj-tst-wus2-xfna-xxsignup-01.azurewebsites.net/api/billing/subscription',
      billingApi: 'https://prj-tst-wus2-xfna-document-01.azurewebsites.net/api/',
      stripeKey: "pk_test_XvxbqWCFOxySfV6j1Ekv4xVo002897fs6F",
      auth0_domain: "tstprojul.us.auth0.com",
      auth0_clientId: "YXoevMT9L7wnAB7Ey3FjS1xfbqkvXp07",
      auth0_audience: "ProjulTstAPI",
      auth0_db: "ProjulTstDatabase",
      qbdClientDownload: "https://prjtstxcusxxsaxxaadb2c01.blob.core.windows.net/$root/ProjulSyncManagerForQBD.exe",
      logRocketKey: 'umgzza/projul-test',
      v2Url: 'https://projulv2-portal-app.azurewebsites.net/',
      apiUrl: "https://prj-tst-wus2-xfna-document-01.azurewebsites.net/api/",
      reconcileApi: "https://prj-tst-wus2-xfna-intgrtns-01.azurewebsites.net/api/",
      accounting_uri: "https://prj-tst-wus2-xfna-acctsvcs-01.azurewebsites.net/api/",
      
      // qbBaseUrl: `http://${QB_IP}/`,
      qbBaseUrl: `https://${QB_IP}/`,

      filesApi: "https://prj-tst-wus2-xfna-xxxfiles-01.azurewebsites.net/api/",
      geolocApi: "https://prj-tst-wus2-xfna-xxgeoloc-01.azurewebsites.net/api/",
      syncApi: "https://prj-tst-wus2-xfna-xxxxsync-01.azurewebsites.net/api/",
      syncManagerLink: 'https://tst-acctsync.projul.com/Files/Projul%20Sync%20Manager.zip',
      remoteConnectorLink: 'https://tst-acctsync.projul.com/Files/Remote%20Connector.zip',
      calendarApi: "https://prj-tst-wus2-xfna-intgrtns-01.azurewebsites.net/api/GoogleCalendar/CheckUserAvailableSlot",
      emailApi: "https://prj-tst-wus2-xfna-xxxemail-01.azurewebsites.net/api/",
      redirectUri: "https://tst-app.projul.com/",
      demoLinkAdder: "&test=true",
      portal_url: "https://tst-portal.projul.com/",
      mapbox_key: "pk.eyJ1IjoicHJvanVsIiwiYSI6ImNrYXljMjgzYjBoZmMycXAxNXE0Z3VoOHEifQ.4kyaGdwXvUjEAon9IFZ9Ug",
      environment: "TST",
      wePayConfig: {
        apiUrl: "https://prj-tst-wus2-xfna-xxxwepay-01.azurewebsites.net/api/accounts/",
        loginUrl: 'https://stage.wepay.com/login',
        kycUrl: 'https://stage-home.wepay.com/verify/',
      }
    }
  }
  else if (window.location.hostname === "dev-app.projul.com" || window.location.hostname === "prj-dev-wus2-xxwa-xxxreact-01.azurewebsites.net") {
    window.env = "DEV";
    return {
      subscriptionApi: 'https://prj-dev-wus2-fna-signup-01.azurewebsites.net/api/billing/subscription',
      billingApi: 'https://prj-dev-wus2-xfna-document-01.azurewebsites.net/api/',
      stripeKey: "pk_test_XvxbqWCFOxySfV6j1Ekv4xVo002897fs6F",
      auth0_domain: "devprojul.us.auth0.com",
      auth0_clientId: "MRngjAXlT12SHhmptHMtbjddWL6SPIeX",
      auth0_audience: "ProjulDevAPI",
      auth0_db: "ProjulDevDatabase",
      qbdClientDownload: "https://prjdevxcusxxsaxxaadb2c01.blob.core.windows.net/$root/ProjulSyncManagerForQBD.exe",
      logRocketKey: 'umgzza/projul-test',
      v2Url: 'https://projulv2-portal-app.azurewebsites.net/',
      apiUrl: "https://prj-dev-wus2-xfna-document-01.azurewebsites.net/api/",
      reconcileApi: "https://prj-dev-wus2-xfna-intgrtns-01.azurewebsites.net/api/",
      accounting_uri: "https://prj-dev-wus2-xfna-acctsvcs-01.azurewebsites.net/api/",
      filesApi: "https://prj-dev-wus2-fna-files-01.azurewebsites.net/api/",
      geolocApi: "https://prj-dev-wus2-fna-geoloc-01.azurewebsites.net/api/",
      // qbBaseUrl: `http://${QB_IP}/`,
      qbBaseUrl: `https://${QB_IP}/`,

      syncApi: "https://prj-dev-wus2-fna-sync-01.azurewebsites.net/api/",
      syncManagerLink: 'https://japanapi.tk/Files/Projul%20Sync%20Manager.zip',
      remoteConnectorLink: 'https://japanapi.tk/Files/Remote%20Connector.zip',
      calendarApi: "https://prj-dev-wus2-xfna-intgrtns-01.azurewebsites.net/api/GoogleCalendar/CheckUserAvailableSlot",
      emailApi: "https://prj-dev-wus2-fna-email-01.azurewebsites.net/api/",
      redirectUri: "https://dev-app.projul.com/",
      demoLinkAdder: "&test=true",
      portal_url: "https://dev-portal.projul.com/",
      mapbox_key: "pk.eyJ1IjoicHJvanVsIiwiYSI6ImNrYXliemp5ejA5Y2gycXJ4OHU2YXZubDUifQ.CNlVG52ru3fxH-hxTTR1pw",
      environment: "DEV",
      wePayConfig: {
        apiUrl: "https://prj-dev-wus2-fna-wepay-01.azurewebsites.net/api/accounts/",
        loginUrl: 'https://stage.wepay.com/login',
        kycUrl: 'https://stage-home.wepay.com/verify/',
      }
    }
  }
  else if (window.location.hostname === "localhost") {
    if (useLocalFunctions) {
      window.env = "DEV";
      return {
        subscriptionApi: 'https://prj-dev-wus2-fna-signup-01.azurewebsites.net/api/billing/subscription',
        billingApi: 'https://prj-dev-wus2-xfna-document-01.azurewebsites.net/api/',
        stripeKey: "pk_test_XvxbqWCFOxySfV6j1Ekv4xVo002897fs6F",
        auth0_domain: "devprojul.us.auth0.com",
        auth0_clientId: "MRngjAXlT12SHhmptHMtbjddWL6SPIeX",
        auth0_audience: "ProjulDevAPI",
        auth0_db: "ProjulDevDatabase",
        qbdClientDownload: "https://prjdevxcusxxsaxxaadb2c01.blob.core.windows.net/$root/ProjulSyncManagerForQBD.exe",
        logRocketKey: 'umgzza/projul-test',
        v2Url: 'https://projulv2-portal-app.azurewebsites.net/',
        apiUrl: "http://localhost:7071/api/",
        reconcileApi: "http://localhost:7071/api/intgrtns",
        accounting_uri: "http://localhost:7078/api/",
        filesApi: "http://localhost:7081/api/",
        
        // qbBaseUrl: `http://${QB_IP}/`,
        qbBaseUrl: `https://${QB_IP}/`,

        syncApi: "https://prj-dev-wus2-fna-sync-01.azurewebsites.net/api/",
        syncManagerLink: 'https://japanapi.tk/Files/Projul%20Sync%20Manager.zip',
        remoteConnectorLink: 'https://japanapi.tk/Files/Remote%20Connector.zip',
        calendarApi: "https://prj-dev-wus2-xfna-intgrtns-01.azurewebsites.net/api/GoogleCalendar/CheckUserAvailableSlot",
        emailApi: "https://prj-dev-wus2-fna-email-01.azurewebsites.net/api/",
        redirectUri: "https://localhost:3000/",
        demoLinkAdder: "&test=true",
        always_dev: true,
        portal_url: "https://dev-portal.projul.com/",
        mapbox_key: "pk.eyJ1IjoicHJvanVsIiwiYSI6ImNrYXliemp5ejA5Y2gycXJ4OHU2YXZubDUifQ.CNlVG52ru3fxH-hxTTR1pw",
        environment: "localhost",
        wePayConfig: {
          apiUrl: "http://localhost:7088/api/accounts/",
          loginUrl: 'https://stage.wepay.com/login',
          kycUrl: 'https://stage-home.wepay.com/verify/',
        }
      }
    }
    else if(useTSTInLocalhost) {
      window.env = "TST";
      return {
        stripeAccountId: 'price_1M2bt0LNiglk2O80Pc0Jlo9Z',
        subscriptionApi: 'https://prj-tst-wus2-xfna-xxsignup-01.azurewebsites.net/api/billing/subscription',
        billingApi: 'https://prj-tst-wus2-xfna-document-01.azurewebsites.net/api/',
        stripeKey: "pk_test_XvxbqWCFOxySfV6j1Ekv4xVo002897fs6F",
        auth0_domain: "tstprojul.us.auth0.com",
        auth0_clientId: "YXoevMT9L7wnAB7Ey3FjS1xfbqkvXp07",
        auth0_audience: "ProjulTstAPI",
        auth0_db: "ProjulTstDatabase",
        qbdClientDownload: "https://prjtstxcusxxsaxxaadb2c01.blob.core.windows.net/$root/ProjulSyncManagerForQBD.exe",
        logRocketKey: 'umgzza/projul-test',
        v2Url: 'https://projulv2-portal-app.azurewebsites.net/',
        apiUrl: "https://prj-tst-wus2-xfna-document-01.azurewebsites.net/api/",
        reconcileApi: "https://prj-tst-wus2-xfna-intgrtns-01.azurewebsites.net/api/",
        accounting_uri: "https://prj-tst-wus2-xfna-acctsvcs-01.azurewebsites.net/api/",
        filesApi: "https://prj-tst-wus2-xfna-xxxfiles-01.azurewebsites.net/api/",
        geolocApi: "https://prj-tst-wus2-xfna-xxgeoloc-01.azurewebsites.net/api/",
        // qbBaseUrl: `http://${QB_IP}/`,
        qbBaseUrl: `https://${QB_IP}/`,

        syncApi: "https://prj-tst-wus2-fna-sync-01.azurewebsites.net/api/",
        syncManagerLink: 'https://tst-acctsync.projul.com/Files/Projul%20Sync%20Manager.zip',
        remoteConnectorLink: 'https://tst-acctsync.projul.com/Files/Remote%20Connector.zip',
        calendarApi: "https://prj-tst-wus2-xfna-intgrtns-01.azurewebsites.net/api/GoogleCalendar/CheckUserAvailableSlot",
        emailApi: "https://prj-tst-wus2-fna-email-01.azurewebsites.net/api/",
        mapbox_keyclientSecret: 'prOuqhm-Vro-KH11~FG4_sna5wdK08-jpL',
        redirectUri: "https://localhost:3000/",
        demoLinkAdder: "&test=true",
        always_dev: true,
        portal_url: "https://tst-portal.projul.com/",
        mapbox_key: "pk.eyJ1IjoicHJvanVsIiwiYSI6ImNrYXliemp5ejA5Y2gycXJ4OHU2YXZubDUifQ.CNlVG52ru3fxH-hxTTR1pw",
        environment: "localhost",
        wePayConfig: {
          apiUrl: "https://prj-tst-wus2-xfna-xxxwepay-01.azurewebsites.net/api/accounts/",
          loginUrl: 'https://stage.wepay.com/login',
          kycUrl: 'https://stage-home.wepay.com/verify/',
        }
      }
    }
    else if(usePRDInLocalhost) {
      window.env = "PRD";
      return {
        stripeAccountId: 'price_1M6jVMLNiglk2O80QT6HeHCJ',
        subscriptionApi: 'https://api.projul.com/signup/v1/billing/subscription',
        billingApi: 'https://prj-prd-wus2-xfna-document-01.azurewebsites.net/api/',
        stripeKey: "pk_live_9z3kNbZRtrIzHZvVsjljK5ih00In2nxJCo",
        auth0_domain: "signin.projul.com",
        auth0_clientId: "9iiqN6ZSsymYtzt7CNBUtZIxH0rILn06",
        auth0_audience: "ProjulPrdAPI",
        auth0_db: "ProjulPrdDatabase",
        qbdClientDownload: "https://prjprdxcusxxsaxxaadb2c01.blob.core.windows.net/$root/ProjulSyncManagerForQBD.exe",
        logRocketKey: 'umgzza/projul-prod',
        // apiUrl: "https://api.projul.com/documents/v1/",
        // accounting_uri: "https://api.projul.com/accountingservices/v1/",
        // filesApi: "https://api.projul.com/files/v1/",

        v2Url: 'https://appv2.projul.com/',
        apiUrl: "https://prj-prd-wus2-xfna-document-01.azurewebsites.net/api/",
        reconcileApi: "https://prj-prd-wus2-xfna-intgrtns-01.azurewebsites.net/api/",
        accounting_uri: "https://prj-prd-wus2-xfna-acctsvcs-01.azurewebsites.net/api/",
        filesApi: "https://prj-prd-wus2-xfna-xxxfiles-01.azurewebsites.net/api/",
        
        syncApi: "https://prj-prd-wus2-xfna-xxxxsync-01.azurewebsites.net/api/",
        syncManagerLink: 'https://acctsync.projul.com/Files/Projul%20Sync%20Manager.zip',
        remoteConnectorLink: 'https://acctsync.projul.com/Files/Remote%20Connector.zip',
        calendarApi: "https://prj-prd-wus2-xfna-intgrtns-01.azurewebsites.net/api/GoogleCalendar/CheckUserAvailableSlot",
        emailApi: "https://prj-prd-wus2-xfna-xxxemail-01.azurewebsites.net/api/",
        redirectUri: "https://localhost:3000/",
        demoLinkAdder: "",
        portal_url: "https://portal.projul.com/",
        mapbox_key: "pk.eyJ1IjoicHJvanVsIiwiYSI6ImNrYXljNDFpbDAxMnEycXF0ZjB5azhmeXMifQ.JrexLCHFHT-FfzO4Pvqaqg",
        environment: "PRD",
        dromoKey: 'f6d767f4-170c-4541-b9bf-01bf13c28224',
        wePayConfig: {
          apiUrl: "https://prj-prd-wus2-xfna-xxxwepay-01.azurewebsites.net/api/accounts/",
          loginUrl: 'https://wepay.com/login',
          kycUrl: 'https://home.wepay.com/verify/',
        }
      }
    }
    else {
      window.env = "DEV";
      return {
        subscriptionApi: 'https://prj-dev-wus2-fna-signup-01.azurewebsites.net/api/billing/subscription',
        billingApi: 'https://prj-dev-wus2-xfna-document-01.azurewebsites.net/api/',
        stripeKey: "pk_test_XvxbqWCFOxySfV6j1Ekv4xVo002897fs6F",
        auth0_domain: "devprojul.us.auth0.com",
        auth0_clientId: "MRngjAXlT12SHhmptHMtbjddWL6SPIeX",
        auth0_audience: "ProjulDevAPI",
        auth0_db: "ProjulDevDatabase",
        qbdClientDownload: "https://prjdevxcusxxsaxxaadb2c01.blob.core.windows.net/$root/ProjulSyncManagerForQBD.exe",
        logRocketKey: 'umgzza/projul-test',
        v2Url: 'https://projulv2-portal-app.azurewebsites.net/',
        apiUrl: "https://prj-dev-wus2-xfna-document-01.azurewebsites.net/api/",
        reconcileApi: "https://prj-dev-wus2-xfna-intgrtns-01.azurewebsites.net/api/",
        accounting_uri: "https://prj-dev-wus2-xfna-acctsvcs-01.azurewebsites.net/api/",
        filesApi: "https://prj-dev-wus2-fna-files-01.azurewebsites.net/api/",
        geolocApi: "https://prj-dev-wus2-fna-geoloc-01.azurewebsites.net/api/",
        // qbBaseUrl: `http://${QB_IP}/`,
        qbBaseUrl: `https://${QB_IP}/`,

        syncApi: "https://prj-dev-wus2-fna-sync-01.azurewebsites.net/api/",
        syncManagerLink: 'https://japanapi.tk/Files/Projul%20Sync%20Manager.zip',
        remoteConnectorLink: 'https://japanapi.tk/Files/Remote%20Connector.zip',
        calendarApi: "https://prj-dev-wus2-xfna-intgrtns-01.azurewebsites.net/api/GoogleCalendar/CheckUserAvailableSlot",
        emailApi: "https://prj-dev-wus2-fna-email-01.azurewebsites.net/api/",
        redirectUri: "https://localhost:3000/",
        demoLinkAdder: "&test=true",
        always_dev: true,
        portal_url: "https://dev-portal.projul.com/",
        mapbox_key: "pk.eyJ1IjoicHJvanVsIiwiYSI6ImNrYXliemp5ejA5Y2gycXJ4OHU2YXZubDUifQ.CNlVG52ru3fxH-hxTTR1pw",
        environment: "localhost",
        wePayConfig: {
          apiUrl: "https://prj-dev-wus2-fna-wepay-01.azurewebsites.net/api/accounts/",
          loginUrl: 'https://stage.wepay.com/login',
          kycUrl: 'https://stage-home.wepay.com/verify/',
        }
      }
    }
  }
  else if (window.location.hostname === Matt_IP) {
    window.env = "DEV";
    return {
      subscriptionApi: 'https://prj-dev-wus2-fna-signup-01.azurewebsites.net/api/billing/subscription',
      billingApi: 'https://prj-dev-wus2-xfna-document-01.azurewebsites.net/api/',
      stripeKey: "pk_test_XvxbqWCFOxySfV6j1Ekv4xVo002897fs6F",
      auth0_domain: "devprojul.us.auth0.com",
      auth0_clientId: "MRngjAXlT12SHhmptHMtbjddWL6SPIeX",
      auth0_audience: "ProjulDevAPI",
      auth0_db: "ProjulDevDatabase",
      qbdClientDownload: "https://prjdevxcusxxsaxxaadb2c01.blob.core.windows.net/$root/ProjulSyncManagerForQBD.exe",
      logRocketKey: 'umgzza/projul-test',
      v2Url: 'https://projulv2-portal-app.azurewebsites.net/',
      apiUrl: "https://prj-dev-wus2-xfna-document-01.azurewebsites.net/api/",
      reconcileApi: "https://prj-dev-wus2-xfna-intgrtns-01.azurewebsites.net/api/",
      accounting_uri: "https://prj-dev-wus2-xfna-acctsvcs-01.azurewebsites.net/api/",
      filesApi: "https://prj-dev-wus2-fna-files-01.azurewebsites.net/api/",
      geolocApi: "https://prj-dev-wus2-fna-geoloc-01.azurewebsites.net/api/",
      // qbBaseUrl: `http://${QB_IP}/`,
      qbBaseUrl: `https://${QB_IP}/`,

      syncApi: "https://prj-dev-wus2-fna-sync-01.azurewebsites.net/api/",
      syncManagerLink: 'https://japanapi.tk/Files/Projul%20Sync%20Manager.zip',
      remoteConnectorLink: 'https://japanapi.tk/Files/Remote%20Connector.zip',
      calendarApi: "https://prj-dev-wus2-xfna-intgrtns-01.azurewebsites.net/api/GoogleCalendar/CheckUserAvailableSlot",
      emailApi: "https://prj-dev-wus2-fna-email-01.azurewebsites.net/api/",
      redirectUri: `https://${Matt_IP}:3000/`,
      demoLinkAdder: "&test=true",
      portal_url: "https://dev-portal.projul.com/",
      mapbox_key: "pk.eyJ1IjoicHJvanVsIiwiYSI6ImNrYXliemp5ejA5Y2gycXJ4OHU2YXZubDUifQ.CNlVG52ru3fxH-hxTTR1pw",
      environment: "DEV",
      wePayConfig: {
        apiUrl: "https://prj-dev-wus2-fna-wepay-01.azurewebsites.net/api/accounts/",
        loginUrl: 'https://stage.wepay.com/login',
        kycUrl: 'https://stage-home.wepay.com/verify/',
      }
    }
  }
  else if (window.location.hostname === Kurt_IP) {
    window.env = "DEV";
    return {
      qbdClientDownload: "https://prjdevxcusxxsaxxaadb2c01.blob.core.windows.net/$root/ProjulSyncManagerForQBD.exe",
      logRocketKey: 'umgzza/projul-test',
      v2Url: 'https://projulv2-portal-app.azurewebsites.net/',
      apiUrl: "https://prj-dev-wus2-xfna-document-01.azurewebsites.net/api/",
      reconcileApi: "https://prj-dev-wus2-xfna-intgrtns-01.azurewebsites.net/api/",
      accounting_uri: "https://prj-dev-wus2-xfna-acctsvcs-01.azurewebsites.net/api/",
      filesApi: "https://prj-dev-wus2-fna-files-01.azurewebsites.net/api/",
      geolocApi: "https://prj-dev-wus2-fna-geoloc-01.azurewebsites.net/api/",
      // qbBaseUrl: `http://${QB_IP}/`,
      qbBaseUrl: `https://${QB_IP}/`,

      syncApi: "https://prj-dev-wus2-fna-sync-01.azurewebsites.net/api/",
      syncManagerLink: 'https://japanapi.tk/Files/Projul%20Sync%20Manager.zip',
      remoteConnectorLink: 'https://japanapi.tk/Files/Remote%20Connector.zip',
      calendarApi: "https://prj-dev-wus2-xfna-intgrtns-01.azurewebsites.net/api/GoogleCalendar/CheckUserAvailableSlot",
      emailApi: "https://prj-dev-wus2-fna-email-01.azurewebsites.net/api/",
      demoLinkAdder: "&test=true",
      always_dev: true,
      environment: "localhost",
      wePayConfig: {
        apiUrl: "https://prj-dev-wus2-fna-wepay-01.azurewebsites.net/api/accounts/",
        loginUrl: 'https://stage.wepay.com/login',
        kycUrl: 'https://stage-home.wepay.com/verify/',
      }
    }
  }
  else if (window.location.hostname === Justin_IP) {
    window.env = "DEV";
    return {
      qbdClientDownload: "https://prjdevxcusxxsaxxaadb2c01.blob.core.windows.net/$root/ProjulSyncManagerForQBD.exe",
      logRocketKey: 'umgzza/projul-test',
      v2Url: 'https://projulv2-portal-app.azurewebsites.net/',
      apiUrl: "https://prj-dev-wus2-xfna-document-01.azurewebsites.net/api/",
      reconcileApi: "https://prj-dev-wus2-xfna-intgrtns-01.azurewebsites.net/api/",
      accounting_uri: "https://prj-dev-wus2-xfna-acctsvcs-01.azurewebsites.net/api/",
      filesApi: "https://prj-dev-wus2-fna-files-01.azurewebsites.net/api/",

      // qbBaseUrl: `http://${QB_IP}/`,
      qbBaseUrl: `https://${QB_IP}/`,

      geolocApi: "https://prj-dev-wus2-fna-geoloc-01.azurewebsites.net/api/",
      syncApi: "https://prj-dev-wus2-fna-sync-01.azurewebsites.net/api/",
      syncManagerLink: 'https://japanapi.tk/Files/Projul%20Sync%20Manager.zip',
      remoteConnectorLink: 'https://japanapi.tk/Files/Remote%20Connector.zip',
      calendarApi: "https://prj-dev-wus2-xfna-intgrtns-01.azurewebsites.net/api/GoogleCalendar/CheckUserAvailableSlot",
      emailApi: "https://prj-dev-wus2-fna-email-01.azurewebsites.net/api/",
      demoLinkAdder: "&test=true",
      always_dev: true,
      environment: "localhost",
      wePayConfig: {
        apiUrl: "https://prj-dev-wus2-fna-wepay-01.azurewebsites.net/api/accounts/",
        loginUrl: 'https://stage.wepay.com/login',
        kycUrl: 'https://stage-home.wepay.com/verify/',
      }
    }
  }
})();
