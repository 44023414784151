import React from 'react';
import MobileLogoWhite from '../assets/images/thumbnail-white.png'

class GetNewApp extends React.Component {


  render () {
    return (
      <div
        id="app"
        className={
          'app-loaded' +
          (true || this.state.modal || this.state.search_mode
            ? ' lock-scroll'
            : '')
        }
      >
        <div
          id="messages"
          style={{background: '#E0E2EA'}}
          className={'get-app' + (true ? ' show' : '')}
        >
          <section>
            <div className="card f-column f-a-center" style={{width:"400px", maxWidth:"100%"}}>
              <h1 className="t-center" style={{fontSize:"2rem", padding: "2rem"}}>Your account has been migrated to Projul V2!</h1>
              <p style={{fontSize: "1.25rem", padding: "0 2rem"}}>Please install and sign in to the new mobile app using your same email and password.</p>
              <br />
              <div className="f-row f-center f-a-center" style={{padding: "2rem"}}>
                {window.OS === 'IOS' ? (
                  <a
                    className="ios-app-store"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`https://apps.apple.com/us/app/projul/id6444810085`}
                  >
                    <span>app store</span>
                  </a>
                ) : (
                  <a
                    className="android-app-store"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`https://play.google.com/store/apps/details?id=com.projul.mobile`}
                  >
                    <span>app store</span>
                  </a>
                )}
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default GetNewApp
