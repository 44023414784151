import React from 'react';
import ReactDOM from 'react-dom';
import { appConfig } from './config/config';
import GetNewApp from './components/get-new-app';
import { getMobileOperatingSystem } from './utilities/device-helper';

require('./styles/themes/theme-light.css');

// document.heyMattItsMobile = true;

window.OS = getMobileOperatingSystem();

function start() {
  ReactDOM.render(
    <GetNewApp />,
    document.getElementById('root')
  );
}

if (document.heyMattItsMobile || window.ReactNativeWebView) {
  start();
}
else {
  window.location.href = appConfig.v2Url;
}